.notice-info .notice-content .title {
  text-align: center;
}
.notice-info .notice-content .title strong {
  font-size: 14px;
  color: #333;
}
.notice-info .notice-content .title span {
  font-size: 16px;
  color: #454545;
  margin-left: 10px;
}
.notice-info .notice-content .content {
  padding: 20px;
  min-height: 100px;
  max-height: 220px;
  overflow-x: hidden;
  overflow-y: auto;
}
.notice-info .layoutconfirm {
  padding-top: 20px;
  text-align: center;
}
