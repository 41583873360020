input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
.loginBox {
  background-color: #ccc;
  height: 620px;
}
.login-icon {
  display: inline-block;
  vertical-align: top;
  font-size: 0px;
  width: 16px;
  height: 18px;
  margin-right: 10px;
}
.login-icon img {
  width: 100%;
}
.err-err-panel .ant-form-explain {
  margin-top: 0;
}
