.findPwd-panel-box {
  position: relative;
  width: 400px;
  height: 523px;
}
.findPwd-panel-box .common-panel-content {
  border-radius: 10px;
  width: 400px;
  height: 563px;
  background: #ffffff;
  position: relative;
  padding: 0.38rem 0.4rem 0.15rem;
  z-index: 3;
}
.findPwd-panel-box .common-panel-content .panel-title {
  display: block;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  color: #454545;
}
.findPwd-panel-box .common-panel-content .login-form-button {
  width: 100%;
}
.findPwd-panel-box .common-panel-content .reg-duanxin3 {
  text-align: center;
}
.findPwd-panel-box .user-title {
  color: #333;
  font-size: 0.14rem;
  display: block;
  text-align: center;
  padding-top: 0.32rem;
}
.findPwd-panel-box .checkCode {
  height: 39px;
  position: relative;
}
.findPwd-panel-box .checkCode img {
  height: 32px;
  line-height: 39px;
  position: relative;
  top: 3px;
  display: block;
  margin: 0 auto;
}
.findPwd-panel-box .errlist {
  color: #f5222d;
}
.findPwd-panel-box .icon-pravite {
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-left: 0.1rem;
  position: relative;
  top: 5px;
}
.findPwd-panel-box .top8 {
  top: 8px;
}
.findPwd-panel-box .resister {
  text-align: right;
  display: flex;
}
.findPwd-panel-box .resister a {
  /*margin-left: 20/100rem;*/
}
.findPwd-panel-box .resister a:nth-child(1) {
  margin-right: 0.14rem;
}
.findPwd-panel-box .ant-input:hover {
  box-shadow: none;
  border-color: #dcdcdc !important;
}
.findPwd-panel-box .ant-input {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #dcdcdc;
  /*border-color: #dcdcdc ;*/
  background: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  overflow: hidden;
}
.findPwd-panel-box .ant-input:focus {
  box-shadow: none;
  outline: 0;
  border-color: #dcdcdc;
}
.findPwd-panel-box .has-error {
  border-color: #dcdcdc !important;
}
.findPwd-panel-box .ant-form-explain {
  text-align: left !important;
  display: block;
}
.findPwd-panel-box span {
  color: #666;
}
.findPwd-panel-box .min-dark {
  color: #666666;
}
.findPwd-panel-box button span {
  color: #f0f0f0;
}
.findPwd-panel-box a {
  color: #0084ff;
}
.findPwd-panel-box .ant-tabs-nav {
  width: 100%;
}
.findPwd-panel-box .ant-tabs-nav .ant-tabs-tab {
  width: 50% !important;
  margin: 0;
  text-align: center;
}
.find-password .ant-btn {
  border-radius: 0px;
}
.find-password .ant-input {
  padding-left: 30px !important;
  border-radius: 0 !important;
}
.find-password .ant-input-prefix {
  left: 0px !important;
}
.find-password .ant-input::placeholder {
  margin-left: 10px;
  font-size: 12px;
  color: #999;
}
.find-password .ant-input:focus {
  border-color: #ff4d4f;
  border: none;
  border-bottom: 1px solid #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none !important;
}
.button-color-color {
  color: #0084ff;
}
.button-color-color:hover {
  color: #0092f8;
}
.button-color-color:active {
  color: #006fec;
}
.button-color-color:visited {
  color: #0084ff;
}
.button-color-bgcolor {
  background-color: #07baff !important;
}
.button-color-color {
  color: #07baff;
}
.title33 {
  font-size: 17px;
  text-align: center;
  color: #666666;
  margin-top: -7px;
  margin-bottom: 10px;
}
.user-find .ant-tabs-nav {
  width: 100%;
}
.user-find .ant-tabs-nav .ant-tabs-tab {
  width: 50% !important;
  margin: 0;
  text-align: center;
}
.login-form-button-go {
  color: #0084ff;
  font-size: 0.14rem;
  display: block;
  text-align: center;
  margin-top: -10px;
  height: 0.32rem;
  line-height: 0.32rem;
  border: 1px solid transparent;
  transition: border-color 0.3s linear;
  border: 1px solid #0084ff;
  margin-top: 10px;
}
.login-form-button-go:hover {
  color: white;
  border: 1px solid #0092f8;
  background-color: #0092f8;
}
.login-form-button-go:active {
  color: white;
  border: 1px solid #2a62ff;
  background-color: #2a62ff;
}
