.antdButtonSelf {
  border: #0084ff !important;
  background-color: #0084ff !important;
}
.antdButtonSelf:hover {
  border-color: #0092f8 !important;
  background-color: #0092f8 !important;
}
.antdButtonSelf:active {
  border-color: #006fec;
  background-color: #006fec !important;
}
.antdButtonSelf:visited {
  border: #0084ff !important;
  background-color: #0084ff !important;
}
.uvsec-style .loginAndRegisterForm .login-user {
  margin-bottom: 30px;
}
.uvsec-style .loginAndRegisterForm .login-paw {
  margin-bottom: 50px;
}
.loginAndRegisterForm .resister {
  padding-left: 62px;
}
@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .loginAndRegisterForm .resister {
    padding-left: 36px;
  }
}
.loginAndRegisterForm .ant-input {
  border: none;
  border-bottom: 1px solid #DCDCDC;
  border-radius: 0;
  padding-left: 30px !important;
}
.loginAndRegisterForm .ant-input-prefix {
  left: 0px !important;
}
.loginAndRegisterForm .ant-input::placeholder {
  margin-left: 10px;
  font-size: 12px;
  color: #999;
}
.loginAndRegisterForm .ant-input:focus {
  border-color: #ff4d4f;
  outline: 0;
  box-shadow: none !important;
}
.loginAndRegisterForm .login-user,
.loginAndRegisterForm .login-paw {
  margin-bottom: 19px;
  border-radius: 0 !important;
}
.loginAndRegisterForm .login-paw {
  margin-bottom: 30px;
}
.loginAndRegisterForm input::placeholder {
  margin-left: 10px;
  font-size: 14px;
  color: #999999;
}
.loginAndRegisterForm .qqWechatIcon {
  cursor: pointer;
}
.loginAndRegisterForm .elseLogin {
  height: 12px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 32px;
}
.loginAndRegisterForm .login-form-button {
  margin-bottom: 14px;
  height: 40px;
  border-radius: 0 !important;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.loginAndRegisterForm .forgetPassword {
  height: 12px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 32px;
}
.loginAndRegisterForm .forgetPassword:hover {
  color: #07baff;
}
