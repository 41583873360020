.regitem .ant-btn {
  border-radius: 0px;
}
.regitem .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.regitem .ant-checkbox:hover .ant-checkbox-inner,
.regitem .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #0092f8 !important;
}
.regitem .ant-input {
  padding-left: 30px !important;
  border-radius: 0 !important;
}
.regitem .ant-input-prefix {
  left: 0px !important;
}
.regitem .ant-input::placeholder {
  margin-left: 10px;
  font-size: 12px;
  color: #999;
}
.regitem .ant-input:focus {
  border-color: #ff4d4f;
  border: none;
  border-bottom: 1px solid #ff4d4f;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: none !important;
}
.regitem .mail-regbutton {
  margin-top: -10px !important;
}
.regitem .mail-res-argee-ment {
  margin-bottom: 0px !important;
}
@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .regitem .res-argee-ment {
    margin: 0rem 0 0.1rem !important;
  }
}
