.actice-email {
  margin-bottom: 10px;
}
.button-color-color {
  color: #0084ff;
}
.button-color-color:hover {
  color: #0092f8;
}
.button-color-color:active {
  color: #2a62ff;
}
.reg-from .ant-tabs-nav .ant-tabs-tab {
  width: 50% !important;
  margin: 0;
  text-align: center;
}
.reg-from .ant-tabs-nav {
  width: 100%;
}
.reg-from .reg-duanxin {
  text-align: right;
}
.reg-from .another-login {
  text-align: right;
}
.reg-from .loginIcon {
  display: flex;
}
.reg-from .loginIcon div:nth-child(1)::before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: bottom;
}
.reg-from .loginIcon div:nth-child(2) {
  width: 1px;
  flex-grow: 1;
  text-align: right;
}
.reg-from .margin-min-item {
  margin-bottom: 0;
}
.reg-from .res-argee-ment {
  margin: 0.24rem 0;
}
