.poc-html {
  padding-top: 50px;
  overflow-x: hidden;
}
.poc-html .poc-html-code {
  background-color: #000;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
}
