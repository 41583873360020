.find-password .ant-btn {
  border-radius: 0px;
}
.find-password .ant-input {
  padding-left: 30px !important;
  border-radius: 0 !important;
}
.find-password .ant-input-prefix {
  left: 0px !important;
}
.find-password .ant-input::placeholder {
  margin-left: 10px;
  font-size: 12px;
  color: #999;
}
.find-password .ant-input:focus {
  border-color: #ff4d4f;
  border: none;
  border-bottom: 1px solid #ff4d4f;
  border-right-width: 1px!important;
  outline: 0;
  box-shadow: none !important;
}
.button-color-color {
  color: #0084ff;
}
.button-color-color:hover {
  color: #0092f8;
}
.button-color-color:active {
  color: #006fec;
}
.button-color-color:visited {
  color: #0084ff;
}
.button-color-bgcolor {
  background-color: #07baff !important;
}
.button-color-color {
  color: #07baff;
}
.title33 {
  font-size: 17px;
  text-align: center;
  color: #666666;
  margin-top: -7px;
  margin-bottom: 10px;
}
.user-find .ant-tabs-nav {
  width: 100%;
}
.user-find .ant-tabs-nav .ant-tabs-tab {
  width: 50% !important;
  margin: 0;
  text-align: center;
}
.login-form-button-go {
  color: #0084ff;
  font-size: 0.14rem;
  display: block;
  text-align: center;
  margin-top: -10px;
  height: 0.32rem;
  line-height: 0.32rem;
  border: 1px solid transparent;
  transition: border-color 0.3s linear;
  border: 1px solid #0084ff;
  margin-top: 20px;
}
.login-form-button-go:hover {
  color: white;
  border: 1px solid #0092f8;
  background-color: #0092f8;
}
.login-form-button-go:active {
  color: white;
  border: 1px solid #2a62ff;
  background-color: #2a62ff;
}
