.login-header {
  padding-top: 48px;
  height: 100px;
  display: flex;
  color: white;
  justify-content: space-around;
}
.login-header #officeWebEnterprise {
  color: white;
  margin-right: 41px;
}
.login-header .header-left {
  height: 53px;
  margin-left: 5%;
}
.login-header .header-left img {
  position: relative;
  top: -8px;
  width: auto;
  max-height: 53px;
  height: 100%;
  border-radius: 5px;
}
.login-header .header-left .header-top-title {
  width: 239px;
  font-size: 24px;
  line-height: 53px;
  font-weight: 400;
  color: #fefefe;
  margin-left: 20px;
}
.login-header .header-right .wordss {
  width: 94px;
  height: 23px;
  font-size: 18px;
  font-weight: 400;
  color: #fefefe;
  line-height: 53px;
}
.login-header .header-right .qq-img {
  width: 24px;
  position: relative;
  top: -4px;
  margin-right: 5px;
}
