.activeEmail-panel-box {
  position: relative;
  width: 400px;
  height: 523px;
}
.activeEmail-panel-box .common-panel-content {
  border-radius: 10px;
  width: 400px;
  height: 523px;
  background: #ffffff;
  position: relative;
  padding: 100px 0.4rem 0.15rem;
  z-index: 3;
}
.activeEmail-panel-box .common-panel-content .panel-title {
  display: block;
  margin-bottom: 40px;
  text-align: center;
  font-size: 24px;
  color: #454545;
}
.activeEmail-panel-box .common-panel-content .login-form-button {
  width: 100%;
}
.activeEmail-panel-box .user-title {
  color: #333;
  font-size: 0.14rem;
  display: block;
  text-align: center;
  padding-top: 0.32rem;
}
.activeEmail-panel-box .checkCode {
  height: 39px;
  position: relative;
}
.activeEmail-panel-box .checkCode img {
  height: 32px;
  line-height: 39px;
  position: relative;
  top: 3px;
  display: block;
  margin: 0 auto;
}
.activeEmail-panel-box .errlist {
  color: #f5222d;
}
.activeEmail-panel-box .icon-pravite {
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-left: 0.1rem;
  position: relative;
  top: 5px;
}
.activeEmail-panel-box .top8 {
  top: 8px;
}
.activeEmail-panel-box .resister {
  text-align: right;
  display: flex;
}
.activeEmail-panel-box .resister a {
  /*margin-left: 20/100rem;*/
}
.activeEmail-panel-box .resister a:nth-child(1) {
  margin-right: 0.14rem;
}
.activeEmail-panel-box .ant-input:hover {
  box-shadow: none;
  border-color: #dcdcdc !important;
}
.activeEmail-panel-box .ant-input {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #dcdcdc;
  /*border-color: #dcdcdc ;*/
  background: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  overflow: hidden;
}
.activeEmail-panel-box .ant-input:focus {
  box-shadow: none;
  outline: 0;
  border-color: #dcdcdc;
}
.activeEmail-panel-box .has-error {
  border-color: #dcdcdc !important;
}
.activeEmail-panel-box .ant-form-explain {
  text-align: left !important;
  display: block;
}
.activeEmail-panel-box span {
  color: #666;
}
.activeEmail-panel-box .min-dark {
  color: #666666;
}
.activeEmail-panel-box button span {
  color: #f0f0f0;
}
.activeEmail-panel-box a {
  color: #0084ff;
}
.activeEmail-panel-box .ant-tabs-nav {
  width: 100%;
}
.activeEmail-panel-box .ant-tabs-nav .ant-tabs-tab {
  width: 50% !important;
  margin: 0;
  text-align: center;
}
