.uvsec-style #user {
  background-image: url("/images/uvser_bg.png");
}
#user {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #17274c;
  overflow: auto;
  position: absolute;
  z-index: 1;
  background-image: url("/images/xiushi.png");
  background-repeat: no-repeat;
  background-size: cover;
}
#user .copy-right {
  color: #c9c9c9;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 10px;
}
@media screen and (min-width: 1000px) and (max-width: 1300px) {
  #user {
    background-image: url("/images/xiushi2.png");
  }
  #user .copy-right {
    color: #c9c9c9;
    font-size: 12px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 10px;
  }
}
#user .activ-back {
  position: relative;
  text-align: center;
  width: 400px;
  height: 200px;
  display: table;
  right: -50%;
  margin-top: 200px;
  transform: translateX(-50%);
  z-index: 400;
}
#user .activ-back .activ {
  display: table-cell;
  width: 400px;
  height: 200px;
  background-color: #fff;
  vertical-align: middle;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 0.16rem;
}
#user .activ-back .activ .redirect {
  font-size: 0.14rem;
  color: #999;
  display: block;
  padding-top: 6px;
}
#user .activ-back .activ .redirect i {
  font-style: normal;
  color: red;
  text-decoration: underline;
  padding-right: 4px;
}
#root {
  height: 100%;
}
